<template>
    <a-layout class="yj-conten-div">
        <a-table :columns="columns" :data-source="datalist" :pagination="{ pageSize:11 }" rowKey="ID">
            <span slot="action" slot-scope="text, record">
                <a-divider type="vertical" />
                <a @click="ViewDetails(record.ID)">查看信息</a>
                <a-divider type="vertical" />
            </span>
        </a-table>
    </a-layout>
</template>
<script>
    import http from "@/Plugin/Http.js";
    import util from "@/Plugin/util.js";
    //import moment from 'moment';
    const columns = [
        {
            title: 'ID',
            dataIndex: 'ID',
        },
        {
            title: '机构ID',
            dataIndex: 'CustomerID',
        },
        {
            title: '名称',
            dataIndex: 'Name',
        },
        {
            title: '创建人',
            dataIndex: 'UserID',
        },
        {
            title: '录入时间',
            customRender: (text, item) => {
                var temp = new Date(util.TimeStamptoDateTime(item.InputTime, 'yyyy-MM-dd hh:mm:ss'));
                return util.formatDate3(temp);
            }
        },
        {
            title: '开始时间',
            customRender: (text, item) => {
                var temp = new Date(util.TimeStamptoDateTime(item.StartTime, 'yyyy-MM-dd hh:mm:ss'));
                return util.formatDate3(temp);
            }
        },
        {
            title: '结束时间',
            customRender: (text, item) => {
                var temp = new Date(util.TimeStamptoDateTime(item.EndTime, 'yyyy-MM-dd hh:mm:ss'));
                return util.formatDate3(temp);
            }
        },
        {
            title: '发布状态',
            dataIndex: 'ReleaseStatus',
        },
        {
            title: '充值金额',
            dataIndex: 'RechargeMoney',
        },
        {
            title: '奖励金额',
            dataIndex: 'RewardsMoney',
        },
        {
            title: '订单号',
            dataIndex: 'OrderNumber',
        },
        {
            title: '激励类型',
            dataIndex: 'RewardsType',
        },
        {
            title: '账户金额',
            dataIndex: 'AccountMoney',
        },
        {
            title: '活动id',
            dataIndex: 'ActivityID',
        },
        {
            title: '操作',
            scopedSlots: {
                customRender: 'action'
            },
        }
    ]
    export default {
        name: "",
        data() {
            return {
                datalist:[],
                columns,
            }

        },
        props: {
            //组件属性
        },
        methods: {//方法
            cha: function () {
                var self = this;
                var op = {
                    url: "/Interactive/GetInteractions",
                    OnSuccess: function (data) {
                        self.datalist = data.data;
                        console.log(data.data);
                    },
                };
                http.Post(op);
            },
            ViewDetails: function (ID) {
                    this.$router.replace("/InteractiveModel/ViewDetails?ID=" + ID); 
            },
            Data_Interactiv: function () {
                var self = this;
                var op = {
                    url: "/InteractiveModel/Interactive/GetInteractions",
                    OnSuccess: function (data) {
                        self.datalist = data.data;
                        console.log("数据？")
                        console.log(JSON.stringify(data.data));
                    },
                };
                http.Post(op);
            }
        },
        computed: {
            //计算属性
        },
        watch: {
            //侦听属性
        },
        components: {
            //组件
        },
        beforeCreate: function () {
            //生命周期，创建前
        },
        created: function () {
            //生命周期，创建完成
        },
        beforeMount: function () {
            //生命周期，组装前
        },
        mounted: function () {
            this.cha();
            //生命周期，组装完成
        },
        beforeUpdate: function () {
            //生命周期，data更新前
        },
        updated: function () {
            //生命周期，data更新完成
        },
        beforeDestroy: function () {
            //生命周期，销毁前
        },
        destroyed: function () {
            //生命周期，销毁完成
        },
    }

</script>